<template>
    <div class="invitedRecord">
        <div v-if="teamList.length > 0">
            <table style="width:913px;border:1px solid #dfdfdf;" cellspacing="0">
                <tr style="height:46px;background: #f9fafc;">
                    <th style="width:140px;">头像</th>
                    <th>姓名</th>
                    <th>勋章</th>
                    <th>邀请时间</th>
                </tr>
                <tr style="height:92px;" v-for="(im,ix) in teamList[page-1]" :key="ix">
                    <td>
                        <img :src="im.portrait" style="width:60px;height:60px;border-radius:100px;" alt="">
                    </td>
                    <td>{{im.real_name ? im.real_name : im.name}}</td>
                    <td>
                        <!-- <div style="background-size:cover;" :style="{background:`url(${im.icon})`}">

                        </div>
                        <img :src="im.icon" style="width:60px;height:60px;border-radius:100px;" alt=""> -->
                        {{im.medal_name}}
                    </td>
                    <td>{{im.ctime}}</td>
                </tr>
            </table>
            <el-pagination
            style="margin-top:37px;"
            background
            layout="prev, pager, next"
            :total="totalCount"
            :page-size="10"
            @current-change="currentChange">
            </el-pagination>
        </div>
        <div v-else style="width:100%;height:100%;" class="flex alignCenter spacearound">
            <temp-data></temp-data>
        </div>
    </div>
</template>
<script>
import { my_team } from '@/utils/Api/userList'
import tempData from '@/components/tempData'
export default {
    name:'invitedRecord',
    components:{tempData},
    data(){
        return{
            teamList:[],
            page: 1,
            totalCount: 0
        }
    },
    created(){
        this.inititalbefore()
    },
    methods:{
        inititalbefore(){
            let params = {
                page: this.page,
                limit: 10 
            }
            if(this.teamList[this.page-1]){
                return
            }
            this.initial(params)
        },
        async initial(params){
            let {res, count} = await my_team(params)
            if(res){
                this.teamList.push(res)
                this.totalCount = count
            }
        },
        currentChange(value){
            this.scrollTop()
            this.page = value
            if(this.teamList[value-1]){return}
            this.inititalbefore()
        }
    }
}
</script>
<style lang="scss" scoped>
table{
    margin-top: 20px;
    margin-left: 22px;
}
    th{
        border: none;
        border-bottom: 1px solid #dfdfdf;
    }
    td{
        color: #333;
        font-size: 14px;
        border-bottom: 1px solid #dfdfdf;
    }
    tr:last-child{
        td{border: none;}
    }
</style>